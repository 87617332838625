@font-face {
  font-family: 'SofiaPro';
  src: url('../fonts/SofiaProRegular.woff') format('woff'),
       url('../fonts/SofiaProRegular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SofiaPro';
  src: url('../fonts/SofiaProBold.woff') format('woff'),
       url('../fonts/SofiaProBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SofiaPro';
  src: url('../fonts/SofiaProLight.woff') format('woff'),
       url('../fonts/SofiaProLight.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
