.sheet {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  min-height: 100vh;
  border: 1rem solid $blue;
  background-color: #fff;
  pointer-events: none;
  opacity: 0;
  padding: 80px 2rem 0;
  background-image: url("../img/background.svg");
  background-position: bottom center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.4s;

  &.active {
    pointer-events: auto;
    opacity: 1;
  }

  &.help {
    z-index: 999;
  }

  &.outro {
    z-index: 89;
  }
}

.row {
  width: 100%;
  text-align: center;
  display: flex;

  .col {
    margin: auto;
    text-align: left;
    max-width: 640px;
    align-items: flex-start;

    &.left {
      width: 40%;
    }

    &.right {
      width: 60%;
      padding: 0 0 0 1rem;
    }

    .image {
      width: 100%;
      height: auto;
    }

    .row {
      .col {
        .textarea {
          height: 8rem;
        }

        &:nth-child(1) {
          margin-right: 0.5rem;
        }

        &:nth-child(2) {
          margin-left: 0.5rem;
        }
      }
    }
  }
}

.button {
  cursor: pointer;
  padding: 1rem 2rem;
  background-color: $orange;
  color: #fff;
  text-align: center;
  display: inline-block;
  margin: 1rem 0;
  font-size: 1rem;
  font-family: "SofiaPro";
  font-size: 1rem;
  border: none;
  transition: all .4s;

  &:hover {
    background-color: darken($orange, 20%);
  }
}

.textarea {
  background-color: #EFEFEF;
  border: none;
  border-bottom: 2px solid #000;
  width: 100%;
  height: 8rem;
  padding: 1rem;
  font-family: "SofiaPro";
  font-size: 0.8rem;
  line-height: 1.4;
}

.inputs {
  width: 100%;
  margin-top: 1rem;

  .inputrow {
    width: 100%;
    margin-bottom: 0.32rem;
    display: block;
    position: relative;
    background-color: #000;

    &::before {
      position: absolute;
      color: #fff;
      text-align: center;
      width: 3rem;
      left: 0;
      top: 50%;
      transform: translate3d(0,-50%,0);
    }

    &:nth-child(1) {
      &::before {
        content: "1";
      }
    }

    &:nth-child(2) {
      &::before {
        content: "2";
      }
    }

    &:nth-child(3) {
      &::before {
        content: "3";
      }
    }

    &.email {
      &::before {
        content: none;
      }

      .mailicon {
        position: absolute;
        color: #fff;
        text-align: center;
        width: 3rem;
        height: 2rem;
        left: 0;
        top: 50%;
        transform: translate3d(0,-50%,0);
        background-image: url("../img/mail.svg");
        background-position: center;
        background-size: 40%;
        background-repeat: no-repeat;
      }
    }
  }

  .input {
    width: calc(100% - 3rem);
    padding: 1rem;
    position: relative;
    background-color: #EFEFEF;
    border: none;
    border-bottom: 2px solid #000;
    margin-left: 3rem;
    font-family: "SofiaPro";
    font-size: 0.8rem;
  }
}
