* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

*:focus {
  outline: none;
}

html {
  font-size: calc(12px + 0.64vw);
}

body {
  font-family: "SofiaPro";
  font-size: 1rem;
}

h1 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

h3 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

p {
  font-weight: 300;
  font-size: 0.8rem;
  line-height: 1.4;
  margin-bottom: 1rem;
}

strong {
  font-weight: 600;
}
// colors
$blue: #4A3F7E;
$orange: #E67B26; // layout

.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100px;
  z-index: 998;

  .logo {
    position: absolute;
    left: 2rem;
    top: 2rem;
    font-size: 1.4rem;
    font-weight: 600;
  }

  .helpopen {
    position: absolute;
    right: 2rem;
    top: 2rem;
    width: 2rem;
    height: 2rem;
    background-image: url("../img/help.svg");
    background-position: center;
    background-size: 90%;
    background-repeat: no-repeat;
    cursor: pointer;
  }
}

.credits {
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  width: 6rem;
  height: 3rem;
  background-image: url("../img/logo.png");
  background-position: right bottom;
  background-size: 90%;
  background-repeat: no-repeat;
}

.helpclose {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 2rem;
  height: 2rem;
  background-image: url("../img/close.svg");
  background-position: center;
  background-size: 90%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.counter {
  position: absolute;
  left: 2rem;
  bottom: 2rem;
  padding: 0.42rem 0.32rem 0.28rem;
  background-color: #000;
  border-radius: 1rem;
  color: #fff;
  text-align: center;
  width: 4rem;
  display: none;
  font-size: 0.8rem;

  label {
    line-height: 0;
    padding: 0;
    margin: 0;
  }

  &.active {
    display: block;
  }
}

.steps {
  position: absolute;
  top: 2rem;
  left: 50%;
  display: flex;
  transform: translate3d(-50%,0,0);

  .step {
    display: inline-block;
    width: 2rem;
    height: 1rem;
    margin: 0 4px;
    font-size: 0.62rem;
    border-bottom: 4px solid #ccc;
    cursor: not-allowed;
    transition: all 0.4s;

    &.active {
      border-bottom: 4px solid $orange;
      cursor: pointer;
    }

    &.visited {
      border-bottom: 4px solid $blue;
      cursor: pointer;

      &.active {
        border-bottom: 4px solid $orange;
        cursor: pointer;
      }
    }
  }
}
